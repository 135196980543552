import { sapSyncSpecificationFileApi } from '@/api'
import { MappingType, ODataSystem, SyncConfigDTO } from '@coac-gmbh/sap-sync-configuration-api'
import { addToast } from '@/composables/useToasts'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export interface Characteristic {
  CharacterKeySource: string
  CharacterNameSource: string
  CharacterKeyDestination: string
  CharacterNameDestination: string
}

export interface Phrase {
  CharKeySource: string
  PhraseKeySource: string
  PhraseTextSource: string
  PhraseKeyDestination: string
  PhraseTextDestination: string
  CharKeyDestination: string
}

export interface Property {
  sub_char_category_source: string
  identifier_source: string
  sub_char_category_destination: string
  identifier_destination: string
}

export interface Substance {
  substance_mabas: string
  sub_record_no_mabas: string
  substance_star: string
  sub_record_no_star: string
}

export interface ItemData extends SyncConfigDTO {
  fileDataError?: boolean
  filesProcessed: FileData[]
}

export interface FileData {
  fileId?: string
  file?: File
  mappedType?: MappingType
  rows: RowData[]
  headers: string[]
}

export interface CreationForm {
  name: string
  sourceSystem: ODataSystem | ''
  destinationSystem: ODataSystem | ''
}

export interface UpdateForm {
  name: string
}

export type RowData = Property | Phrase | Substance

export const CHARACTERISTIC_HEADERS = [
  'CharacterKeySource',
  'CharacterNameSource',
  'CharacterKeyDestination',
  'CharacterNameDestination',
]

export const PHRASE_HEADERS = [
  'CharKeySource',
  'PhraseKeySource',
  'PhraseTextSource',
  'PhraseKeyDestination',
  'PhraseTextDestination',
  'CharKeyDestination',
]

export const PROPERTY_HEADERS = [
  'sub_char_category_source',
  'identifier_source',
  'sub_char_category_destination',
  'identifier_destination',
]

export const SUBSTANCE_HEADERS = ['substance_mabas', 'sub_record_no_mabas', 'substance_star', 'sub_record_no_star']

export function isSyncNotification(data: any): boolean {
  if (!data) return false

  const { source, destination, propHeaders, propData } = data
  if (!source || !destination || !propHeaders || !propData) return false

  return (
    typeof source === 'string' &&
    typeof destination === 'string' &&
    Array.isArray(propHeaders) &&
    Array.isArray(propData)
  )
}

export function mapODataSystem(value: ODataSystem): string {
  const mapping: Record<ODataSystem, string> = {
    [ODataSystem.Bas]: 'BAS',
    [ODataSystem.Zcq100]: 'ZCQ-100',
    [ODataSystem.Zcq300]: 'ZCQ-300',
  }

  return mapping[value] || value
}

export default function useSyncConfiguration() {
  const { t } = useI18n()
  const isSyncActionLoading = ref<boolean>(false)

  async function addSyncItemIntoSkipList(data: any, hideModal: () => void) {
    if (!isSyncNotification(data)) {
      addToast({ text: 'Invalid sync notification data', variant: 'danger' })
      return
    }
    isSyncActionLoading.value = true
    const { source, destination, propHeaders, propData } = data

    try {
      await sapSyncSpecificationFileApi.addSkipList({
        skipListRequest: {
          sourceSystem: source,
          destinationSystem: destination,
          propHeader: propHeaders || [],
          propData: propData || [],
        },
      })
      addToast({ text: 'Item added to skip list', variant: 'success' })
      hideModal()
    } catch (error) {
      addToast({ text: 'Error adding item to skip list', variant: 'danger' })
    }

    setTimeout(() => {
      isSyncActionLoading.value = false
    }, 1000)
  }

  function getSyncSkipListModalFields(data: any) {
    return [
      { title: t('Config ID:'), value: data.data.configId || '---' },
      { title: t('Config Name:'), value: data.data.configName || '---' },
      { title: t('Source:'), value: data.data.source || '---' },
      { title: t('Destination:'), value: data.data.destination || '---' },
      { title: t('Headers:'), value: data.data.propHeaders?.join(', ') || t('No headers available') },
      { title: t('Data:'), value: data.data.propData?.join(', ') || t('No data available') },
    ]
  }

  return {
    isSyncActionLoading,
    addSyncItemIntoSkipList,
    getSyncSkipListModalFields,
  }
}
