import { computed } from 'vue'
import { useAuth } from '@/auth'
import { RouteLocationRaw, useRouter } from 'vue-router'
import i18n from '@/plugins/i18n'

export enum Roles {
  Admin = 'admin',
  Member = 'member',
}

// We only add the pages that we are going to use
export enum PagesList {
  Dashboard = 'dashboard',
  ExtractionList = 'extractions',
  ExtractionDetail = 'extractions-id',
  ApprovalList = 'approvals',
  ApprovalDetail = 'approvals-id',
  PassportsList = 'passports',
  RequestsList = 'requests',
  RequestsDetail = 'requests-id',
  RequestsNew = 'requests-new',
  DataAssetList = 'data-assets',
  DataAssetDetail = 'data-assets-dataAssetId',
  DataObjectDetail = 'data-assets-dataAssetId-dataObjectId',
  MaterialsComparison = 'data-assets-dataAssetId-dataObjectId-comparison',
  Library = 'library',
  Assessment = 'assessment',
  DataLookup = 'data-lookup',
  QueryBuilder = 'query-builder',
  AIChat = 'ai-chat',
}

export enum Permissions {
  // Dashboad
  OpenDashboard = 'ROLE_OPEN_DASHBOARD',
  UseDashboardExtractions = 'ROLE_USE_DASHBOARD_EXTRACTIONS',
  ApproveDashboardInformation = 'ROLE_APPROVE_DASHBOARD_INFORMATION',
  // Extraction
  OpenExtractions = 'ROLE_OPEN_EXTRACTIONS',
  CreateExtraction = 'ROLE_CREATE_EXTRACTION',
  UpdateExtraction = 'ROLE_UPDATE_EXTRACTION',
  DeleteExtraction = 'ROLE_DELETE_EXTRACTION',
  UploadDocumentsInExtraction = 'ROLE_UPLOAD_DOCUMENTS_IN_EXTRACTION',
  AllowQuickExtraction = 'ROLE_ALLOW_QUICK_EXTRACTION',
  AllowDefineDataAssetExtraction = 'ROLE_ALLOW_DEFINE_DATA_ASSET_EXTRACTION',
  SelectPassportExtraction = 'ROLE_SELECT_PASSPORT_EXTRACTION',
  CreatePassportExtraction = 'ROLE_CREATE_PASSPORT_EXTRACTION',
  // Approval
  OpenApprovals = 'ROLE_OPEN_APPROVALS',
  ApproveExtractedDocuments = 'ROLE_APPROVE_EXTRACTED_DOCUMENTS',
  // Requests
  CreateRequests = 'ROLE_CREATE_REQUESTS',
  UpdateRequests = 'ROLE_UPDATE_REQUESTS',
  ViewRequests = 'ROLE_VIEW_REQUESTS',
  AddProductsToRequests = 'ROLE_ADD_PRODUCTS_TO_REQUESTS',
  AddNewItemsToRequests = 'ROLE_ADD_NEW_ITEMS_TO_REQUESTS',
  RequestsIngredients = 'ROLE_REQUESTS_INGREDIENTS',
  CloseRequestsCommunications = 'ROLE_CLOSE_REQUESTS_COMMUNICATIONS',
  // Data assets
  OpenDataAssets = 'ROLE_OPEN_DATA_ASSETS',
  CreateDataAssets = 'ROLE_CREATE_DATA_ASSETS',
  UpdateDataAssets = 'ROLE_UPDATE_DATA_ASSETS',
  DeleteDataAssets = 'ROLE_DELETE_DATA_ASSETS', // The 'mark data assets' permissions will be handled by the 'delete' permission for now, because is the unique action generated after mark some data asset
  DownloadDataAssets = 'ROLE_DOWNLOAD_DATA_ASSETS',
  ShareDataAssets = 'ROLE_SHARE_DATA_ASSETS',
  // Data objects (Materials)
  CreateMaterials = 'ROLE_CREATE_DATA_OBJECTS',
  SearchMaterials = 'ROLE_SEARCH_MATERIALS',
  OpenMaterials = 'ROLE_OPEN_MATERIALS',
  ShareMaterials = 'ROLE_SHARE_MATERIALS',
  DownloadMaterials = 'ROLE_DOWNLOAD_MATERIALS',
  DeleteMaterials = 'ROLE_DELETE_MATERIALS', // The 'mark materials' permissions will be handled by the 'delete' permission for now, because is the unique action generated after mark some material
  AddReferencesToMaterials = 'ROLE_ADD_REFERENCES_TO_MATERIALS',
  AddInformationToMaterials = 'ROLE_ADD_INFORMATION_TO_MATERIALS',
  AddInformationItemToMaterials = 'ROLE_ADD_INFORMATION_ITEM_TO_MATERIALS',
  AddInformationTemplateToMaterials = 'ROLE_ADD_INFORMATION_TEMPLATE_TO_MATERIALS',
  StoreInSAP = 'ROLE_STORE_IN_SAP',
  SelectPassportsInMaterials = 'ROLE_SELECT_PASSPORTS_IN_MATERIALS',
  CompareMaterials = 'ROLE_COMPARE_MATERIALS',
  SeeDocumentInCompareMaterials = 'ROLE_SEE_DOCUMENTS_IN_COMPARE_MATERIALS',
  UpdateItemsInCompareMaterials = 'ROLE_UPDATE_ITEMS_IN_COMPARE_MATERIALS',
  DeleteItemsInCompareMaterials = 'ROLE_DELETE_ITEMS_IN_COMPARE_MATERIALS',
  SeeVersionHistoryOfMaterials = 'ROLE_SEE_VERSION_HISTORY_OF_MATERIALS',
  // Passports
  CreatePassports = 'ROLE_CREATE_PASSPORTS',
  UpdatePassports = 'ROLE_UPDATE_PASSPORTS',
  DeletePassports = 'ROLE_DELETE_PASSPORTS',
  // Library
  OpenLibrary = 'ROLE_OPEN_LIBRARY',
  UploadDocumentsInLibrary = 'ROLE_UPLOAD_DOCUMENTS_IN_LIBRARY',
  OpenDocumentsInLibrary = 'ROLE_OPEN_DOCUMENTS_IN_LIBRARY',
  DeleteDocumentsInLibrary = 'ROLE_DELETE_DOCUMENTS_IN_LIBRARY',
  // Assessments
  CrudAssessment = 'ROLE_CRUD_ASSESSMENT',
  // Data Lookup
  CrudDataLookup = 'ROLE_CRUD_DATA_LOOKUP',
  // Query builder
  OpenQueryBuilder = 'ROLE_OPEN_QUERIES',
  // AI CHAT
  OpenAIChat = 'ROLE_OPEN_AI_CHAT',
}

export default function useRolesAndPermissions() {
  const auth = useAuth()
  const router = useRouter()
  const permissionDeniedRoute = {
    name: 'error',
    params: {
      statusCode: '403',
      // eslint-disable-next-line
      // @ts-ignore
      message: i18n.global.t('You do not have permissions to view this page'),
    },
  }
  const ROLES_WITH_PERMISSIONS = [
    {
      type: Roles.Admin,
      permissions: Object.values(Permissions), // Assing all the permissions
    },
    {
      type: Roles.Member,
      permissions: [
        Permissions.OpenDashboard,
        Permissions.OpenExtractions,
        Permissions.OpenApprovals,
        Permissions.OpenLibrary,
        Permissions.UploadDocumentsInExtraction,
        Permissions.ApproveDashboardInformation,
        Permissions.UseDashboardExtractions,
        Permissions.SearchMaterials,
        Permissions.ApproveExtractedDocuments,
        Permissions.CreateMaterials,
        Permissions.OpenDataAssets,
        Permissions.UpdateDataAssets,
        Permissions.DownloadDataAssets,
        Permissions.OpenMaterials,
        Permissions.ShareMaterials,
        Permissions.DownloadMaterials,
        Permissions.DeleteMaterials,
        Permissions.AddReferencesToMaterials,
        Permissions.AddInformationToMaterials,
        Permissions.AddInformationItemToMaterials,
        Permissions.AddInformationTemplateToMaterials,
        Permissions.StoreInSAP,
        Permissions.CompareMaterials,
        Permissions.SeeDocumentInCompareMaterials,
        Permissions.UpdateItemsInCompareMaterials,
        Permissions.DeleteItemsInCompareMaterials,
        Permissions.SeeVersionHistoryOfMaterials,
        Permissions.OpenDocumentsInLibrary,
      ],
    },
  ]

  const userRolesWithPermissions = computed(() => {
    return {
      permissions: auth.user?.permissions || [],
      restrictedPages: getRestrictedPagesByPermissions(auth.user?.permissions).map((p) => p.toString()),
    }
  })

  function getRestrictedPagesByPermissions(userPermissions?: readonly string[]) {
    let pagesToRestrict = Object.values(PagesList)

    if (!userPermissions) {
      return pagesToRestrict
    }

    if (userPermissions.includes(Permissions.OpenDashboard)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.Dashboard)
    }

    if (userPermissions.includes(Permissions.OpenExtractions)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.ExtractionList)
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.ExtractionDetail)
    }

    if (
      userPermissions.includes(Permissions.CreateExtraction) &&
      userPermissions.includes(Permissions.UpdateExtraction)
    ) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.ExtractionDetail)
    }

    if (userPermissions.includes(Permissions.OpenApprovals)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.ApprovalList)
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.ApprovalDetail)
    }

    if (
      userPermissions.includes(Permissions.CreatePassports) &&
      userPermissions.includes(Permissions.UpdatePassports) &&
      userPermissions.includes(Permissions.DeletePassports)
    ) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.PassportsList)
    }

    if (userPermissions.includes(Permissions.OpenDataAssets)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.DataAssetList)
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.DataAssetDetail)
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.DataObjectDetail)
    }

    if (userPermissions.includes(Permissions.OpenMaterials)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.DataObjectDetail)
    }

    if (userPermissions.includes(Permissions.ViewRequests)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.RequestsList)
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.RequestsDetail)
    }

    if (userPermissions.includes(Permissions.CreateRequests)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.RequestsNew)
    }

    if (userPermissions.includes(Permissions.CompareMaterials)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.MaterialsComparison)
    }

    if (userPermissions.includes(Permissions.CrudAssessment)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.Assessment)
    }

    if (userPermissions.includes(Permissions.CrudDataLookup)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.DataLookup)
    }

    if (userPermissions.includes(Permissions.OpenLibrary)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.Library)
    }

    if (userPermissions.includes(Permissions.OpenQueryBuilder)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.QueryBuilder)
    }

    if (userPermissions.includes(Permissions.OpenAIChat)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.AIChat)
    }

    return pagesToRestrict
  }

  function hasPermission(permission: Permissions) {
    return (
      userRolesWithPermissions.value?.permissions && userRolesWithPermissions.value?.permissions.includes(permission)
    )
  }

  function hasRedirectPermissions(permission: Permissions, path: RouteLocationRaw) {
    if (
      userRolesWithPermissions.value?.permissions &&
      userRolesWithPermissions.value?.permissions.includes(permission)
    ) {
      router.push(path)
    }
  }

  function toErrorPageDueToInsufficientPermissions() {
    router.replace(permissionDeniedRoute)
  }

  return {
    ROLES_WITH_PERMISSIONS,
    userRolesWithPermissions,
    Permissions,
    permissionDeniedRoute,
    hasPermission,
    hasRedirectPermissions,
    toErrorPageDueToInsufficientPermissions,
  }
}
