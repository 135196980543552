import { ref } from 'vue'
import Modal from '@/components/modals/Modal.vue'

export default function useModal() {
  const modal = ref<InstanceType<typeof Modal>>()

  function openModal() {
    modal.value?.show()
  }

  function hideModal() {
    modal.value?.hide()
  }

  return {
    modal,
    openModal,
    hideModal,
  }
}
